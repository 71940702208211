.app-scrollable-container {
  &.favourite-page {
    .favourite-title-text {
      h3.ant-typography {
        font-weight: 900;
        font-size: 24px;
        line-height: 30px;
        color: #6461b4;
      }
    }
    .action-btn {
      border: 2px solid #6461b4;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 11px 15px;
      width: 130px;
      right: 0;
      position: absolute;
      align-items: center;
      text-align: center;
      color: #6461b4;
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
      &.added-item {
        background: #f0f0fc;
        border: 2px solid #b5b6ff;
        color: #b5b6ff;
      }
    }
  }
}
.app-content-container-favourite {
  padding: 24px 12px 0 24px;
  .app-table .table-body .item-row {
    box-shadow: 0px 5px 20px -5px rgba(132, 128, 255, 0.3);
  }
  .info-group-container .text-info {
    max-width: 460px !important;
    @media only screen and (max-width: 1280px) {
      max-width: 260px !important;
    }
  }
}
.new-container-favourite {
  padding: 24px 12px 12px 24px !important;
}
