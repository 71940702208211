.app-modal {
  min-width: 476px !important;
  padding: 0;

  .ant-modal-content {
    max-height: 704px;
    background: #F6F9FF;
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.04), 0 1px 7px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 32px 24px 24px 24px;
    display: flex;
    flex-direction: column;

    .app-modal-back {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      padding: 0;
      color: rgba(0, 0, 0, 0.45);
      font-weight: 700;
      line-height: 1;
      text-decoration: none;
      background: transparent;
      border: 0;
      outline: 0;
      cursor: pointer;
      -webkit-transition: color 0.3s;
      transition: color 0.3s;

      .app-modal-back-icon {
        display: block;
        width: 56px;
        height: 56px;
        font-size: 16px;
        font-style: normal;
        line-height: 56px;
        text-align: center;
        text-transform: none;
        text-rendering: auto;
      }
    }

    .ant-modal-close-x {
      .ant-modal-close-icon {
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    .ant-modal-header {
      background: none;
      border: none;
      text-align: center;
      margin-bottom: 16px;

      .ant-modal-title {
        font-size: 32px;
        font-weight: 700;
        color: #6461B4;
      }
    }

    .ant-modal-body {
      padding: 0;
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      color: #4F4E66;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }

    .ant-modal-footer {
      border-top: none;
      padding: 0;

      .modal-button-container {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;

        .ant-btn {
          flex: 1;
          margin: 32px 0 0 0;
          height: 70px;
        }
      }
    }
  }
}