.status-tag-container {
  .ant-tag {
    border: none;
    border-radius: 6px;
  }

  .status-tag {
    width: 116px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding: 4px 8px;
    margin: 0;
  }

  .status-tag:first-letter {
    text-transform: uppercase;
  }

  .pending {
    color: #FF884B;
    background-color: rgba(255, 136, 75, 0.15);
  }

  .received {
    color: #4BC09C;
    background-color: rgba(75, 192, 156, 0.15);
  }

  .damaged {
    color: #EF5F5F;
    background: rgba(239, 95, 95, 0.15);
  }

  .submitted {
    color: #4F4E66;
    background: rgba(79, 78, 102, 0.15);
  }

  .failed {
    color: #FF0000;
    background: linear-gradient(0deg, rgba(255, 0, 0, 0.15), rgba(255, 0, 0, 0.15)), #FFFFFF;
  }
}
