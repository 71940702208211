.lending-good-categories {
  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0 24px;
    // padding: 0 24px;
    width: 100%;
    .left-header {
      .title-info {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #4f4e66;
        margin-right: 40px;
        margin-bottom: 8px;
        .title-value {
          color: #828282;
        }
      }
    }
    .title-header {
      font-weight: 900;
      font-size: 24px;
      line-height: 36px;
      color: #6461b4;
    }
  }
  .page-content {
    padding: 0 12px 0 2px;
    margin-top: 16px;
    height: calc(var(--vh, 1vh) * 100 - 212px);
    overflow: auto;
    .item-group {
      display: flex;
      margin: 0 -6px;
      width: calc(100% + 12px);
      margin-bottom: 16px;
      .favourite-item {
  
        border: 4px solid #d0d1ff;
        box-sizing: border-box;
        box-shadow: 0px 5px 20px -5px rgba(132, 128, 255, 0.3);
        border-radius: 16px;
        width: calc(100% / 3);
        height: 216px;
        margin: 0 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: white;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        &:first-child {
          margin: 0 6px 0 4px;
        }
        &:last-child {
          margin: 0 4px 0 6px;
        }
        &.item-0 {
          background: linear-gradient(81.97deg, #a69bea 1.05%, #b530bc 84.79%);
        }
        &.item-1 {
          background: linear-gradient(272.96deg, #2593d0 0%, #80d1ff 100%);
        }
        &.item-2 {
          background: linear-gradient(120.76deg, #65c4e1 0%, #89d7bf 100%);
        }
        .selected-text {
          font-size: 16px;
          line-height: 24px;
        }
        .selected-value {
          font-weight: bold;
        }
      }
      &.categories-group {
        flex-wrap: wrap;
        width: calc(100% + 16px);
        margin: 0 -8px;
        .normal-item {
          background: #ffffff;
          border: 2px solid #d0d1ff;
          box-sizing: border-box;
          box-shadow: 0px 5px 20px -5px rgba(132, 128, 255, 0.3);
          border-radius: 16px;
          font-weight: bold;
          font-size: 20px;
          line-height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          color: #4f4e66;
          height: 137px;
          width: calc(25% - 12px);
          margin: 6px;
          cursor: pointer;
        }
      }
    }
  }
  .page-footer {
    position: absolute;
    bottom: 0;
    padding: 24px 0;
    background: unset;
    .footer-btn {
      font-weight: bold;
      font-size: 20px;
      line-height: 1.5715;
      text-align: center;
      color: #6461b4;
      border: 2px solid #5855a6;
      box-sizing: border-box;
      border-radius: 10px;
      height: 70px;
      padding: 20px 88px;
      background-color: #f3f3f9;
      padding: unset !important;
      width: 226px;
    }
  }
  .title-info {
    color: #4f4e66;
    margin-right: 40px;
    margin-bottom: 8px;
    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
    color: #6461b4;
    .title-value {
      color: #828282;
    }
  }
}