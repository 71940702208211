.wapper-my-account {
  margin: 27px 12px 10px 0;
  min-width: 851px !important;
  .title-header {
    font-weight: 900;
    font-size: 32px;
    line-height: 30px;
    color: #6461B4;
    margin-top: 10px;
    margin-bottom: 38px;
    font-family: NotoSansTCBlack;
  }
  .section-infor {
    margin-bottom: 16px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 16px;
    .title-infor {
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
      color: #4F4E66;
      margin-bottom: 16px;
    }
    .dash {
      border-top: 1px solid #B5B6FF;
    }

  }
  .row-account {
    display: flex;
    margin-top: 24px;
    margin-left: 8px;
    .email {
      margin-bottom: 8px;
    }
    .col-account-left {
      flex: 1
    }
    .col-account-right {
      flex: 2
    }
    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #979797;
    }
    .value {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #4F4E66;
    }
    .back-button {
      border: 2px solid #6461B4;
      box-sizing: border-box;
      border-radius: 10px;
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #6461B4;
      // padding: 24px 32px 27px;
      margin: 5px 0 0;
      height: 55px;
    }
  }
}
.modal-change-password {
  .ant-modal-title {
    font-family: NotoSansTCBlack;
    font-size: 24px !important;
  }
  .ant-modal-content {
    width: 376px;
    margin-left: auto;
    margin-right: auto;
    border: 3px solid #D0D1FF;
    box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.04), 0px 1px 7px rgba(0, 0, 0, 0.08);
  }
  .ant-modal-header {
    margin-top: 10px;
  }
}
.wapper-modal-my-account {
  .input-field {
    &:first-child {
      margin-top: 18px;
    }
    padding: 12px;
    .MuiFormControl-fullWidth {
      width: 280px;
    }
    .text-error-login {
      font-size: 10px;
      line-height: 15px;
      color: #EF5F5F;
      text-align: left;
      margin-top: 5px;
    }
    .MuiOutlinedInput-root {
      height: 53px;      
    }
    .MuiFormLabel-root.Mui-error {
      color: #EF5F5F !important;
    }
    .PrivateNotchedOutline-legendLabelled-5{
      font-weight: bold;
      font-family: 'NotoSansTC';
      font-size: 10px;
    }
    .Mui-error .MuiOutlinedInput-notchedOutline {
      // border-color: #EF5F5F;
    }
    .MuiInputLabel-shrink {
      font-weight: bold;
      transform: translate(14.5px, -6px) scale(1);
      font-size: 10px;
    }
    .MuiInputLabel-outlined {
      font-family: NotoSansTC;
      color: #828282;
    }
    .MuiFormLabel-root.Mui-focused{
      color: #3f51b5;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: #B5B6FF;
    }
    .MuiOutlinedInput-adornedEnd {
      &:hover {
        border-color: #B5B6FF;        
      }
    }
   
  }

  .modal-button-container {
    // padding: 0 40px;
    justify-content: center;
    .ant-btn-primary {
      width: 280px;
      height: 70px;
      margin-top: 20px;
      text-shadow: unset;
    }
  }
}