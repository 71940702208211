.wapper-login {
    .container-login {
        .row-container {
            .container-form {
                .title-forget-password {
                    font-style: normal;
                    font-weight: 900;
                    font-size: 24px;
                    line-height: 36px;
                    /* identical to box height, or 150% */
                    text-align: center;
                    /* /Thirdary Text */
                    color: #3056A3;
                    padding: 24px;
                }
                .wapper-input-forget-password {
                    
                    .subtitle-forget-password {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 21px;
                        /* or 150% */
                        text-align: center;
                        color: #1D1D1D;
                        padding: 0 35px 32px 35px;
                    }
                    .button {
                        background: linear-gradient(81.97deg, #a69bea 1.05%, #b530bc 84.79%);
                        border: 1px solid #d8d8d8;
                        box-sizing: border-box;
                        box-shadow: 0px 4px 20px rgba(100, 97, 180, 0.1),
                            0px 1px 8px rgba(100, 97, 180, 0.12);
                        border-radius: 10px;
                        height: 54px;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        justify-content: center;
                        width: calc(100% - 96px);
                        font-style: normal;
                        font-weight: bold;
                        font-size: 16px;
                        color: #ffffff;
                        margin-bottom: 72px;
                        margin-left: 48px;
                        margin-right: 48px;
                        @media only screen and (max-width: 576px) {
                            width: calc(100% - 48px);
                            margin-left: 24px;
                            margin-right: 24px;
                        }

                    }
                }
                .wapper-success-forget-password {
                    .subtitle-success-forget-password {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 21px;
                        /* identical to box height, or 150% */

                        text-align: center;

                        color: #1D1D1D;
                    }
                    .wapper-icon-done {
                        justify-content: center;
                        display: flex;
                        margin-top: 32px;
                        margin-bottom: 44px;
                    }
                    .button {
                        background: linear-gradient(81.97deg, #a69bea 1.05%, #b530bc 84.79%);
                        border: 1px solid #d8d8d8;
                        box-sizing: border-box;
                        box-shadow: 0px 4px 20px rgba(100, 97, 180, 0.1),
                            0px 1px 8px rgba(100, 97, 180, 0.12);
                        border-radius: 10px;
                        height: 54px;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        justify-content: center;
                        width: 100%;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 16px;
                        color: #ffffff;
                        
                    }
                    .wapper-inline {
                        display: flex;
                        margin: 24px 0 36px 0;
                        justify-content: center;
                        .text {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 21px;
                            color: #4F4E66;
                        }
                        .button-create-account {
                            padding: 0 0 0 8px;
                            text-decoration-line: underline;
                            color: #3056A3;
                            cursor: pointer;
                        }
                        
                    }
                }
            }
        }
       
    }
}