.app-list {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-right: 12px;

  .loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item-loading {
    width: 100%;
    display: flex;
    padding: 16px;
    justify-content: center;
  }
}