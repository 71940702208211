.container-footer {
  background: #6461b4;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  @media only screen and (min-width: 1280px) {
    padding: 0 114px;
  }
  .text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #fff;
    padding: 16px;
    @media only screen and (max-width: 576px) {
      padding-bottom: 0;
    }
  }
  .inline {
    display: flex;
    justify-content: space-around;
    width: 100%;
    @media only screen and (max-width: 576px) {
      justify-content: space-between;
    }
  }
  .text-term {
    // padding: 16px 0 16px 16px;
    padding: 16px;
  }
  .dash {
    width: 1px;
    height: 8px;
    background: #c4c4c4;
    align-self: center;
  }
  .text-policy-and-disclaimer {
    padding: 16px;
  }
}
