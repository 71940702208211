.app-table {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &.ipad-device {
    .table-header {
      .header-row {
        margin-right: 12px;
      }
    }

    .table-body {
      padding-right: 12px;

      &.content-scrollable {
        padding-right: 12px;
      }
    }
  }

  .table-header {
    padding-bottom: 8px;
    z-index: 1;

    .header-row {
      border-radius: 4px;
      background: #E5EAFB;
      padding: 8px;
      margin-right: 12px;

      .column-cell {
        color: #6461B4;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
      }

      .column-cell:first-child {
        font-size: 16px;
        font-weight: 700;
        padding-left: 12px;
      }
    }
  }

  .table-progress-container {
    flex: 1;
    padding-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .table-body {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 12px;
    position: relative;

    &.content-scrollable {
      padding-right: 7px;
    }

    &::-webkit-scrollbar-track {
      background: none;
    }

    .item-row {
      margin-bottom: 8px;
      border-radius: 6px;
      background: #FFFFFF;
      padding: 8px;
      box-shadow: 0 5px 20px -5px rgba(132, 128, 255, 0.3);

      .item-content-cell {
        display: flex;
        align-items: center;
      }

      .item-content-row {
        .item-content-cell {
          display: flex;
          align-items: center;
        }
      }

      .item-expand-row {
        margin-top: 8px;

        .item-expand-cell {

        }
      }
    }

    .item-row-error {
      border: 2px solid #FF9292;
    }

    .group-item-sticky {
      position: sticky;
      top: 0;
      z-index: 1;
    }

    .group-item-container {
      position: relative;

      .item-row:nth-child(2) {
        margin-top: 8px;
      }
    }

    .group-item-row {
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 700;

      .group-item-head-wrapper {
        background: #817EB8;
        padding: 8px 10px;

        &.full-border-round {
          border-radius: 4px;
        }

        &.half-border-round {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }

        .group-item-content-row {
          .group-item-content-cell:first-child {
            margin-left: 4px;
          }

          .group-item-content-cell {
            display: flex;
            align-items: center;
          }
        }

        .group-item-expand-row {
          margin-top: 8px;
          margin-left: 4px;

          .group-item-expand-cell {

          }
        }
      }

      .group-item-body-wrapper {
        .group-item-extend-row {
          background: #FFFFFF;
          border-bottom-right-radius: 6px;
          border-bottom-left-radius: 6px;
          box-shadow: 0 5px 20px -5px rgba(132, 128, 255, 0.3);

          .group-item-extend-cell {

          }
        }
      }
    }

    .group-item-row-error {
      .group-item-head-wrapper {
        background: #EF5F5F;
      }
    }
  }
}