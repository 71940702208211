.app-pagination {
  width: 100%;
  text-align: center;
  padding: 20px 0;
  font-weight: 700;

  .ant-pagination {
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-pagination-prev, .ant-pagination-next {
      width: 44px;
      height: 44px;

      .ant-pagination-item-link {
        background: #FFFFFF;
        border-radius: 10px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .ant-pagination-item {
      background: none;
      border: none;
      font-size: 24px;

      a {
        color: rgba(100, 97, 180, 1);
      }
    }

    .ant-pagination-item-active {
      font-weight: 900;

      a {
        color: rgba(249, 135, 134, 1);
      }
    }

    .ant-pagination-jump-next {
      pointer-events: none;

      .ant-pagination-item-link {
        .ant-pagination-item-link-icon {
          display: none;
        }

        .ant-pagination-item-ellipsis {
          color: rgba(100, 97, 180, 1);

          &:hover {
            opacity: unset;
          }
        }
      }
    }
  }
}
