.ant-dropdown-menu {
  margin: 0 20px;
}
.reg-text-pass {
  color: red;
  opacity: 0.5;
  padding-left: 5px;
}
.layout-container {
  display: flex;
  width: 100%;
  height: 100%;
  // min-height: 100vh;
  max-width: 1200px;
  margin: auto;
  // min-width: 100vw;
  align-items: flex-start;
  background: #f3f3f9;

  .layout-content {
    width: 100%;
    // min-height: 100vh;
    padding-left: 112px;
    // overflow: hidden;
    // max-height: 100%;
  }
  .layout-order-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.layout-sider {
  box-shadow: 0px 4px 10px rgba(199, 199, 199, 0.25);
  border-radius: 8px;
  background: white;
  margin: 12px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  height: calc(var(--vh, 1vh) * 100 - 24px);
  min-height: 240px;
  overflow: auto;
  img {
    margin-bottom: 56px;
  }

  .menu-sider-item {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    font-weight: 900;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    color: #bdbdd3;
    margin-top: 24px;
    padding: 10px 0;
    width: 77px;
    cursor: pointer;

    .name-tab {
      line-height: 12px;
      font-style: normal;
      font-weight: 900;
      font-size: 10px;
      font-family: NotoSansTCBlack;
      color: black;
    }

    img {
      max-width: 20px;
      max-height: 30px;
      margin: 0 auto;
      margin-bottom: 4px;
    }

    &:hover {
      color: #bdbdd3;
      background: none;
    }

    &:focus {
      color: white;
      background: lightgray;

      img {
        filter: brightness(0) invert(1);
      }
    }

    &.active-item {
      color: white;
      background: linear-gradient(81.97deg, #a69bea 1.05%, #b530bc 84.79%);

      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}
