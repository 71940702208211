.app-pagination-inventory {
  width: 100%;
  text-align: center;
  padding: 20px 0;
  font-weight: 500;

  .ant-pagination {
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-pagination-prev, .ant-pagination-next {
      width: 44px;
      height: 44px;

      .ant-pagination-item-link {
        background: transparent;
        border-radius: 10px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .ant-pagination-item {
      background: none;
      border: none;
      font-size: 18px;

      a {
        color:#4F4E66
      }
    }

    .ant-pagination-item-active {
      // font-weight: 900;
      border: 2px solid var(--border-2, #B5B6FF);
      background-color: white;
      border-radius: 10px;
      // line-height: 36px;
      width: 36px;
      height: 36px;

      a {
        // color: rgba(249, 135, 134, 1);
      }
    }

    .ant-pagination-jump-next {
      pointer-events: none;

      .ant-pagination-item-link {
        .ant-pagination-item-link-icon {
          display: none;
        }

        .ant-pagination-item-ellipsis {
          color: #4F4E66;

          &:hover {
            opacity: unset;
          }
        }
      }
    }
    .ant-pagination-jump-next.ant-pagination-jump-next-custom-icon {
          align-self: end;
    }
  }
}
