.order-detail-container {
  height: 100%;

  .layout-container {
    height: 100%;

    .app-content-container {
      display: flex;
      flex-direction: column;
      padding-bottom: 0;

      .header-group {
        .page-info-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 16px;
          padding-right: 12px;
          min-height: 50px;

          .page-title {
            flex: 1;

            .ant-typography {
              font-family: NotoSansTCBlack;
              font-size: 24px;
              font-weight: 900;
              color: #6461B4;
              margin-bottom: 0;
            }
          }

          .status-tag-container {
            display: flex;
            justify-content: flex-end;
          }

          .info-card {
            border-radius: 8px;
            margin-right: 12px;
            margin-bottom: 8px;
            min-height: 81px;

            .ant-card-body {
              padding: 12px 16px;

              .estimated-delivery-color {
                color: #FF884B;
              }

              .delivery-date-icon {
                width: 16px;
                height: 16px;
                margin-right: 8px;
                vertical-align: baseline;
              }

              .delivery-date-color {
                color: #4BC09C;
              }
            }

            &.card-1 {
              width: 353px;

              .info-group-container {
                .text-info {
                  font-family: NotoSansTCBlack;
                }
              }
            }

            &.card-2 {
              width: 361px;
            }
          }
        }
      }

      .body-group {
        overflow-y: auto;
        flex: 1;
        position: relative;

        .app-table {

          .table-header {
            .header-row {
              .column-cell:first-child {
                color: #4F4E66;
              }
            }
          }

          .item-info-container {
            .item-info {
              font-weight: 700;
              font-size: 18px;
            }

            .item-info-label {
              font-weight: 400;
              color: #4F4E66;

              .ant-divider {
                border-color: #4F4E66;
              }
            }
          }

          .input-cell {
            font-size: 18px;
            font-weight: 700;
            color: #4f4e66;
            text-align: center;

            .ant-btn {
              width: 100%;
              height: 55px;
              padding: 0;
              border-radius: 10px;
              border: 2px solid #b5b6ff;
              font-size: 18px;
              font-weight: 700;
              color: #4f4e66;

              //&:hover {
              //  border: 4px solid #b530bc;
              //  color: #4f4e66;
              //  background: none;
              //}
              //
              //&:focus {
              //  border: 4px solid #b530bc;
              //  color: #4f4e66;
              //  background: none;
              //}
              //
              //&.ant-popover-open {
              //  border: 4px solid #b530bc;
              //  color: #4f4e66;
              //  background: none;
              //}
            }

            &.actual-quantity {
              width: 111px;

              .ant-btn {
                font-weight: 400;
                color: #B5B6FF;
              }
            }

            &.received-quantity {
              width: 102px;
            }

            &.disabled {
              pointer-events: none;
            }
          }
        }

        .message-container {
          width: 100%;
          height: 100%;
          top: 0;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #4F4E66;
          font-size: 20px;
          font-weight: 700;
        }
      }

      .footer-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 24px 12px 24px 0;

        .ant-btn {
          height: 70px;
          padding: 0;
        }

        .back-button {
          width: 226px;
        }

        .update-button {
          width: 351px;
        }
      }
    }
  }
}
