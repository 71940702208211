.received-delivery-container {
  height: 100%;

  .layout-content {
    height: 100%;

    .app-content-container {
      display: flex;
      flex-direction: column;
      padding: 24px 12px 0 24px;

      .header-group {
        .page-info-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 16px;

          .page-title {
            flex: 1;

            .ant-typography {
              font-family: NotoSansTCBlack;
              font-size: 32px;
              font-weight: 900;
              color: #6461B4;
              margin-bottom: 0;
            }
          }

          .app-input-container {
            width: 304px;
            padding-right: 15px;

            svg {
              path {
                fill: #4F4E66;
              }
            }
          }
        }

        .status-filter-container {
          margin-bottom: 16px;

          .ant-radio-group {
            font-weight: 900;

            .ant-radio-button-wrapper {
              height: auto;
              padding: 8px 24px;
              font-size: 20px;
            }

            .ant-radio-button-wrapper:first-child {
              border: 0;
            }

            .ant-radio-button-wrapper:first-child:last-child {
              border-radius: 4px;
            }

            .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
              background: transparent;
              color: #ABAACA;
            }

            .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
              background: #80B2FF;
            }
          }
        }
      }

      .body-group {
        flex: 1;
        overflow-y: hidden;

        .app-list {
          .ant-list {
            .ant-list-item {
              padding: 0 0 12px 0;

              .ant-card {
                border: 1px solid #B5B6FF;
                box-shadow: 0 5px 20px -5px rgba(132, 128, 255, 0.3);
                border-radius: 6px;

                .ant-card-body {
                  padding: 16px;

                  .supplier-name {
                    font-family: NotoSansTCBlack;
                  }

                  .estimated-delivery-color {
                    color: #FF884B;
                  }

                  .delivery-date-icon {
                    width: 16px;
                    height: 16px;
                    margin-right: 8px;
                    vertical-align: baseline;
                  }

                  .delivery-date-color {
                    color: #4BC09C;
                  }

                  .order-status-info {
                    text-align: end;
                    .order-date {
                      display: block;
                      font-size: 18px;
                      color: #3056A3;
                      font-weight: bold;
                    }
                  }

                  .order-no-info-group {
                    .ant-typography {
                      font-size: 16px;
                      color: #4F4E66;
                      margin-bottom: 0;
                    }
                  }

                  .action-group-container {
                    align-items: flex-end;

                    .total-order-number-info {
                      flex: 1;
                      margin-right: 16px;

                      .ant-tag {
                        width: 100%;
                        padding: 8px 16px;
                        background-color: #F0F0FC;
                        margin: 0;
                        border: none;
                        border-radius: 4px;

                        .ant-typography {
                          color: #4F4E66;
                          vertical-align: middle;
                          font-size: 18px;
                          font-weight: bold;
                        }
                      }
                    }

                    .ant-btn {
                      width: 175px;
                      height: 62px;
                      padding: 0;
                      font-size: 18px;
                      &:disabled{
                        background: linear-gradient(269deg, #D9D9D9 0%, rgba(217, 217, 217, 0.43) 100%);;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .message-container {
          width: 100%;
          padding-top: 166px;
          text-align: center;

          .message {
            color: #4F4E66;
            font-size: 20px;
            font-weight: bold;
          }
        }
      }

      .footer-group {
        padding: 24px 12px 24px 0;

        .ant-btn {
          height: 70px;
          width: 100%;
          padding: 0;
        }

        .device-camera-input {
          display: none;
        }
      }
    }
  }
}