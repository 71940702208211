.order-record-container {
  height: 100%;

  .custom-date-input {
    padding: 10px 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 23.17px;
    color: #4F4E66;
    display: flex;
    gap: 15px;
    border: 1px solid #B5B6FF;
    border-radius: 10px;
    background-color: white;
    min-width: 162px;
    justify-content: end;
  }

  .popper-classname {
    padding-top: 0px !important;
  }

  .day-classname {
    font-size: 16px;
    font-weight: 500;
    color: #666;
    line-height: 48px;
    width: 48px;
    border-radius: 50%;

    &:hover {
      border-radius: 50%;
    }

    &:focus-visible {
      outline: none;
    }
  }

  .calendar-classname {
    padding: 16px 8px;
    border-radius: 10px;
    border: 1px solid #B5B6FF;

    .react-datepicker__month {
      .react-datepicker__week {
        .react-datepicker__day--selected {
          color: white;
          background: linear-gradient(180deg, #F98786 0%, #FC537E 100%);
        }
      }
    }

    .react-datepicker__header--custom {
      background-color: white;
      padding-top: 0px;
      padding-bottom: 0px;
      border-bottom: none;

      .react-datepicker__day-names {
        .react-datepicker__day-name {
          font-weight: 500;
          font-size: 16px;
          line-height: 48px;
          width: 48px;
        }
      }
    }
  }

  .calendar-header {
    font-weight: 700;
    background-color: white;
    padding-left: 16px;
    padding-right: 16px;
  }

  .layout-content {
    height: 100%;

    .app-content-container {
      display: flex;
      flex-direction: column;
      padding: 24px 12px 0 24px;
    }

    .header-group {
      .page-info-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 16px;

        .page-title {
          flex: 1;

          .ant-typography {
            font-family: NotoSansTCBlack;
            font-size: 32px;
            font-weight: 900;
            color: #6461B4;
            margin-bottom: 0;
          }
        }
      }

      .status-filter-container {
        margin-bottom: 16px;

        .ant-radio-group {
          font-weight: 900;

          .ant-radio-button-wrapper {
            height: auto;
            padding: 8px 24px;
            font-size: 20px;
          }

          .ant-radio-button-wrapper:first-child {
            border: 0;
          }

          .ant-radio-button-wrapper:first-child:last-child {
            border-radius: 4px;
          }

          .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
            background: transparent;
            color: #ABAACA;
          }

          .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            background: #80B2FF;
          }
        }
      }
    }

    .body-group {
      flex: 1;
      overflow: hidden;

      &::-webkit-scrollbar-track {
        background: none;
      }

      .app-list {
        .ant-list {
          .ant-list-item {
            padding: 0 0 12px 0;

            .ant-card {
              border: 1px solid #B5B6FF;
              box-shadow: 0 5px 16px -5px rgba(85, 81, 180, 0.3);
              border-radius: 6px;

              .ant-card-body {
                padding: 16px;

                .supplier-name {
                  font-family: NotoSansTCBlack;
                }

                .estimated-delivery {
                  font-family: NotoSansTCBlack;
                  color: #FF884B;
                }

                .delivery-date {
                  font-family: NotoSansTCBlack;
                  color: #4BC09C;

                  img.icon {
                    width: 16px;
                    height: 16px;
                    margin-right: 8px;
                    vertical-align: baseline;
                  }
                }

                .order-date-status-info-group {
                  text-align: end;

                  .order-date {
                    display: block;
                    font-size: 18px;
                    color: #3056A3;
                    font-weight: bold;
                    margin-bottom: 12px;
                  }
                }

                .order-no-receipt-no-info-group {
                  .ant-typography {
                    font-size: 16px;
                    color: #4F4E66;
                    margin-bottom: 16px;

                    .divider {
                      border-color: #4F4E66;
                    }
                  }
                }

                .total-order-number-info {
                  .order-item {
                    &:first-of-type {
                      margin-top: 16px;
                    }

                    &:not(&:last-of-type) {
                      margin-bottom: 8px;
                    }

                    border-radius: 6px;
                    background-color: white;
                    box-shadow: 0px 5px 20px -5px rgba(132, 128, 255, 0.3);
                    display: grid;
                    padding: 8px 16px;
                    grid-template-areas: "metadata qty"
                    "name qty";
                    font-size: 18px;
                    font-weight: 700;

                    .metadata {
                      grid-area: metadata;
                      display: flex;
                      font-size: 14px;
                      font-weight: 400;
                    }

                    .name {
                      grid-area: name;
                    }

                    .ordered-or-received-qty {
                      grid-area: qty;
                      justify-self: end;
                      width: 111px;
                      height: 55px;
                      border: 2px solid #B5B6FF;
                      border-radius: 10px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      margin-top: 4px;
                      margin-bottom: 4px;
                    }
                  }

                  .ant-tag {
                    width: 100%;
                    padding: 8px 16px;
                    background-color: #F0F0FC;
                    margin: 0;
                    line-height: unset;
                    border: none;
                    border-radius: 4px;

                    .ant-typography {
                      color: #4F4E66;
                      vertical-align: middle;
                      font-size: 18px;
                      font-weight: bold;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .message-container {
        width: 100%;
        padding-top: 166px;
        text-align: center;

        .message {
          color: #4F4E66;
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }
}