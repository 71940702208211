.lending-confirm-info-store {
  display: flex;
  .date-lending {
    margin-left: 20px;
  }
}

.lending-confirm-table {
  justify-content: stretch;
  flex-grow: 1;
  min-width: 863px;
  flex: 1 1;
  overflow: auto;
  .ant-col {
    height: 100%;
  }

  .app-table {
    .table-header {
      .header-row {
        .column-cell {
          div {
            text-align: center;
          }
        }
      }
    }

    .table-body {
      flex: 1 1 auto;
      .ant-affix {
        position: absolute !important;
        overflow-y: hidden;
        overflow-x: hidden;
        top: 49px !important;
      }
      .item-row {
        align-items: center;
      }
      .group-item-expand-row {
        .group-item-expand-cell {
          img {
            margin-right: 8px;
          }
          .ant-typography {
            color: white;
            font-size: 14px;
            font-weight: 400;
            margin: 0;
          }
        }
      }
      .unit {
        padding: 8px 12px;
        font-weight: bold;
        font-size: 18px;
        border: 2px solid #B5B6FF;
        box-sizing: border-box;
        border-radius: 10px;
        cursor: pointer;
      }
      .borrowed-qty {
        border: 2px solid #6461B4;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 15px 32px;
        right: 0;
        position: absolute;
        align-items: center;
        text-align: center;
        color: #6461B4;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
      }
      .borrowed-quantity {
        button {
          height: 55px;
          padding: 0px 30px;
        }
        
      }
    }
  }
}
.wapper-loading {
  padding: 45px 0 20px
}