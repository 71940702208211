.wapper-login {
  .container-login {
    .row-container {
      .container-form {
        .title-registration-password {
          font-style: normal;
          font-weight: 900;
          font-size: 24px;
          line-height: 36px;
          /* identical to box height, or 150% */
          text-align: center;
          /* /Thirdary Text */
          color: #3056a3;
          padding: 24px;
        }
        .wapper-input-registration {
          align-items: center;
          display: flex;
          flex-direction: column;
          .dash {
            height: 1px;
            background-color: #f1f1f1;
            padding: 0 24px;
            width: calc(100% - 48px);
            margin-bottom: 32px;
          }
          .wapper-group {
            padding: 0 24px 8px;
            width: 100%;
            // border-bottom: 1px solid #F1F1F1;

            .title-group {
              font-weight: bold;
              font-size: 20px;
              line-height: 30px;
              /* identical to box height, or 150% */
              /* Primary text */
              color: #4f4e66;
              padding: 0 24px;
              margin-bottom: 24px;
            }
            .input-field-full {
              padding: 0 24px;
              width: 100%;
              margin-bottom: 24px;
              .text-error-login {
                color: #f44336;
                font-size: 0.75rem;
                margin-top: 3px;
                margin-left: 5px;
              }
            }
            .wapper-inline {
              display: flex;
              justify-content: center;
              padding: 0 12px;
              .title-company-br {
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                /* identical to box height, or 150% */

                /* Primary text */

                color: #4f4e66;
              }
              .not-upload {
                font-weight: normal;
                font-size: 14px;
                line-height: 21px;
                /* identical to box height, or 150% */

                /* Thirdary Text */

                color: #828282;
              }
              .input-field-half {
                padding: 0 12px;
                width: 100%;
                margin-bottom: 24px;
              }
              .wapper-button-upload {
                display: flex;
                justify-content: flex-end;
                @media only screen and (max-width: 576px) {
                  justify-content: flex-start;
                  margin-top: 20px;
                }
                .button-upload {
                  font-style: normal;
                  font-weight: bold;
                  font-size: 18px;
                  line-height: 27px;
                  color: #6461b4;
                  padding: 14px 38px;
                  justify-content: flex-end;
                  display: flex;
                  border: 2px solid #6461b4;
                  box-sizing: border-box;
                  border-radius: 10px;
                  width: fit-content;
                  align-items: center;
                  cursor: pointer;
                }
              }
            }
            .wapper-company-br {
              padding: 0 12px 24px;
            }
            .inline-agree {
              display: flex;
              justify-content: space-between;
              padding: 0 24px;
              .text-agree {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 21px;
                color: #4f4e66;
              }
              padding-bottom: 24px;
            }
            .agree-terms {
              padding: 0 24px;
              font-weight: normal;
              font-size: 14px;
              line-height: 21px;
              color: #4f4e66;
              margin-bottom: 24px;
              .terms-and-conditions {
                font-weight: bold;
                font-size: 14px;
                line-height: 21px;
                text-decoration-line: underline;
                color: #4f4e66;
                font-weight: bold;
              }
            }
          }
          .button {
            @media only screen and (max-width: 576px) {
              width: calc(100% - 48px);
            }
            width: calc(100% - 96px);
            margin-bottom: 30px;
          }
          .forward-to-signin {
            margin-bottom: 50px;
            .haved-account {
              font-size: 14px;
              line-height: 21px;
              color: #000000;
            }
            .button-signin {
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              text-decoration-line: underline;
              color: #000000;
              cursor: pointer;
            }
          }
        }

        .wapper-success-registration {
          margin: 0 48px;
          @media only screen and (max-width: 576px) {
            margin: 0 24px;
          }
          .subtitle-success-reset-password {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            /* identical to box height, or 150% */

            text-align: center;

            color: #1d1d1d;
          }
          .wapper-icon-done {
            justify-content: center;
            display: flex;
            margin-top: 32px;
            margin-bottom: 44px;
            min-height: 20vh;
          }
          .button {
            background: linear-gradient(
              81.97deg,
              #a69bea 1.05%,
              #b530bc 84.79%
            );
            border: 1px solid #d8d8d8;
            box-sizing: border-box;
            box-shadow: 0px 4px 20px rgba(100, 97, 180, 0.1),
              0px 1px 8px rgba(100, 97, 180, 0.12);
            border-radius: 10px;
            height: 54px;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            width: 100%;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            color: #ffffff;
            margin-bottom: 20px;
          }
          .wapper-inline {
            display: flex;
            margin: 24px 0 36px 0;
            justify-content: center;
            .text {
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 21px;
              color: #4f4e66;
            }
            .button-create-account {
              padding: 0 0 0 8px;
              text-decoration-line: underline;
              color: #3056a3;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
.padding-mobile {
  @media only screen and (max-width: 576px) {
    padding: 0 !important;
  }
}
