.app-select-container-borderless {
  .ant-select {
    .ant-select-selector {
      width: auto;
      min-width: 150px;
      height: auto;
      border: 2px solid #B5B6FF;
      border-radius: 10px;
      padding: 0px 42px 0px 0px;

      .ant-select-selection-item {
        width: 100%;
        color: #6461B4;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        line-height: 34px;
      }
    }

    .ant-select-arrow {
      top: 50%;
      right: 21px;

      img {
        width: 14px;
      }
    }

    &:not(.ant-select-disabled):hover {
      .ant-select-selector {
        // border: 2px solid red !important;
        // border-style: none !important;
      }
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      // border: 2px solid #6461B4 !important;
      box-shadow: none !important;
    }
  }

  .ant-select-single.ant-select-show-arrow {
    .ant-select-selection-item {
      padding-right: 0;
    }
  }

  .ant-select-single.ant-select-show-arrow {
    .ant-select-selection-placeholder {
      padding-right: 0;
    }
  }

  .ant-select-single.ant-select-show-arrow.ant-select-open {
    .ant-select-arrow {
      img {
        transition: all 0.5s ease;
        transform: rotate(180deg);
      }
    }
  }

  .ant-select-single.ant-select-show-arrow:not(.ant-select-open) {
    .ant-select-arrow {
      img {
        transition: all 0.5s ease;
        transform: rotate(0deg);
      }
    }
  }
}

.app-select-dropdown {
  padding: 0;
  box-shadow: 0 5px 16px -5px rgba(85, 81, 180, 0.3);
  border-radius: 6px;

  .rc-virtual-list {
    .rc-virtual-list-holder {
      .rc-virtual-list-holder-inner {
        .ant-select-item.ant-select-item-option {
          padding: 16px 24px;
          font-weight: 700;
          font-size: 16px;
          color: #4F4E66;
        }

        .ant-select-item.ant-select-item-option:nth-of-type(odd) {
          background: #F9FAFF;
        }

        .ant-select-item.ant-select-item-option:nth-of-type(even) {
          background: #F1F4FC;
        }
      }
    }
    .rc-virtual-list-scrollbar {
      margin-right: 8px;
      width: 4px !important;

      .rc-virtual-list-scrollbar-thumb {
        background: #bdbdbd !important;
      }
    }
  }
}

