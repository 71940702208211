.drawer-cookie {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #f0f0fc;
  z-index: 1000;
  .MuiBackdrop-root {
    background: transparent;
  }
  .MuiDrawer-paper {
    background: #f0f0fc;
  }

  .cookie-content {
    padding: 24px 0;
    color: #4f4e66;
    width: 726px;
    margin: 0 auto;
    .cookie-title {
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 8px;
    }
    .cookie-text {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 24px;
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-decoration-line: underline;
        color: #6461b4;
        cursor: pointer;
      }
    }
    .button-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      .got-it-btn {
        background: linear-gradient(81.97deg, #a69bea 1.05%, #b530bc 84.79%);
        border: 1px solid #d8d8d8;
        box-sizing: border-box;
        box-shadow: 0px 4px 20px rgba(100, 97, 180, 0.1),
          0px 1px 8px rgba(100, 97, 180, 0.12);
        border-radius: 10px;
        padding: 22px 74px;
        .MuiButton-label {
          color: white;
          font-weight: bold;
          font-size: 18px;
          line-height: 27px;
        }
      }
    }
  }
}
