.borrowing-record-message-container {
  width: 100%;
  padding-top: 166px;
  text-align: center;

  .message {
    color: #4f4e66;
    font-size: 20px;
    font-weight: bold;
  }
}
.page-content-borrow {
  padding: 0 24px;
  height: calc(100vh - 248px);
  overflow: auto;
  .list-borrow-record-container {
    .ant-list-item {
      padding: 0 0 12px 0;
    }

    .ant-card {
      // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      background: #ffffff;
      /* /Border 2 */

      border: 1px solid #b5b6ff;
      box-sizing: border-box;
      /* Card Shadow */

      box-shadow: 0px 5px 20px -5px rgba(132, 128, 255, 0.3);
      border-radius: 6px;
      .ant-card-body {
        padding: 16px;
        .text-info {
          font-family: NotoSansTCBlack;
        }

        .borrow-date-type {
          text-align: end;
          .date {
            display: block;
            font-size: 18px;
            color: #3056a3;
            font-weight: bold;
          }
        }

        .borrow-no {
          display: flex;
          justify-content: space-between;
          align-items: center;
          min-height: 55px;
          .ant-typography {
            font-size: 16px;
            color: #4f4e66;
            margin-bottom: 0;
            .divider {
              border-color: #4f4e66;
            }
          }
          .button-comfirm {
            width: 175px;
            background: linear-gradient(
              81.97deg,
              #a69bea 1.05%,
              #b530bc 84.79%
            );
            border: 1px solid #d8d8d8;
            box-shadow: 0px 4px 20px rgba(100, 97, 180, 0.1),
              0px 1px 8px rgba(100, 97, 180, 0.12);
            border-radius: 10px;
            height: 55px;
            color: #fff;
            font-weight: bold;
            font-size: 18px;
          }
        }
      }
    }
  }
}

.no-margin-bottom {
  margin-bottom: 0;
}
.header-container-borrow {
  margin: 39px 0 17px 0;
  .title-borrow {
    margin-bottom: 31px;
  }
  .ant-typography {
    font-family: NotoSansTCBlack;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 30px;
    color: #6461b4;
    margin-bottom: 0;
  }
  .ant-radio-button-wrapper-checked {
    background: #80b2ff !important;
    border-radius: 4px !important;
    border: none !important;
    font-family: NotoSansTC;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
    padding: 8px auto;
    height: 46px;
    align-items: center;
    display: flex;
  }
  .ant-radio-button-wrapper {
    background: unset;
    border: unset;
    font-family: NotoSansTC;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    padding: 8px auto;
    color: #abaaca;
    transition: unset;
    -webkit-transition: unset;
  }
}

.borrow-record-container {
  height: 100%;

  .layout-content {
    height: 100%;
    .app-scrollable-container {
      overflow-y: hidden !important;
      height: unset;
      -webkit-overflow-scrolling: touch;
      .app-content-container {
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
        padding: 0;
        overflow: hidden;
      }

      .body-group {
        flex: 1;
        overflow: hidden;
        padding-left: 24px;
        height: calc(var(--vh, 1vh) * 100 - 288px);
        &::-webkit-scrollbar-track {
          background: none;
        }
        .ant-list-item {
          padding: 0 0 12px 0;
        }

        .ant-card {
          // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          background: #ffffff;
          /* /Border 2 */

          border: 1px solid #b5b6ff;
          box-sizing: border-box;
          /* Card Shadow */

          box-shadow: 0px 5px 20px -5px rgba(132, 128, 255, 0.3);
          border-radius: 6px;
          .ant-card-body {
            padding: 16px;
            .text-info {
              font-family: NotoSansTCBlack;
            }

            .borrow-date-type {
              text-align: end;
              .date {
                display: block;
                font-size: 18px;
                color: #3056a3;
                font-weight: bold;
              }
            }

            .borrow-no {
              display: flex;
              justify-content: space-between;
              align-items: center;
              min-height: 55px;
              .ant-typography {
                font-size: 16px;
                color: #4f4e66;
                margin-bottom: 0;
                .divider {
                  border-color: #4f4e66;
                }
              }
              .button-comfirm {
                width: 175px;
                background: linear-gradient(
                  81.97deg,
                  #a69bea 1.05%,
                  #b530bc 84.79%
                );
                border: 1px solid #d8d8d8;
                box-shadow: 0px 4px 20px rgba(100, 97, 180, 0.1),
                  0px 1px 8px rgba(100, 97, 180, 0.12);
                border-radius: 10px;
                height: 55px;
                color: #fff;
                font-weight: bold;
                font-size: 18px;
              }
            }
          }
        }
        .app-list {
          height: calc(var(--vh, 1vh) * 100 - 288px);
        }
      }
    }
    .page-footer {
      position: absolute;
      bottom: 0;
      padding: 24px 0 24px;
      background: #f3f3f9;
      width: calc(100% - 162px);
      left: 135px;
      right: 0;
      .lending-form-btn {
        background: linear-gradient(81.97deg, #a69bea 1.05%, #b530bc 84.79%);
        border: 1px solid #d8d8d8;
        box-sizing: border-box;
        /* Button Shardow */

        box-shadow: 0px 4px 20px rgba(100, 97, 180, 0.1),
          0px 1px 8px rgba(100, 97, 180, 0.12);
        border-radius: 10px;
        width: 100%;
        height: 70px;
        color: #ffffff;
        font-size: 20px;

        font-weight: bold;
      }
    }
  }
}
@media only screen and (min-width: 1200px) {
  .borrow-record-container {
    .page-footer {
      width: calc(1200px - 150px) !important;
      left: calc((100vw - 1200px) / 2 + 125px) !important;
      .lending-form-btn {
        margin-left: 10px;
      }
    }
  }
}
.container-margin-right {
  margin-right: 12px;
}
