.wapper-login {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  .container-login {
    width: 100%;
    background: linear-gradient(
        323deg,
        rgba(197, 194, 255, 0) -17.77%,
        rgba(121, 117, 214, 0.31) 89.47%
      ),
      url(../../assets/images/login_bg.svg);
    /* height: 100vh; */
    height: 380px;
    @media only screen and (max-width: 576px) {
      height: 160px;
    }
    background-repeat: no-repeat;
    max-width: 1440px;
    background-position-x: center;
    background-size: cover;
    .row-container {
      min-height: calc(
        100vh - 50px - 92px
      ); // height footer is 50px and height header is 92px
      .container-form {
        background: #ffffff;
        /* Bg */
        border: 1px solid #f0f0fc;
        box-sizing: border-box;
        /* Card Shadow */
        box-shadow: 0px 5px 20px -5px rgba(132, 128, 255, 0.3);
        border-radius: 6px;
        align-items: center;
        display: flex;
        flex-direction: column;
        // margin-bottom: 124px;
        width: 480px;
        @media only screen and (max-width: 576px) {
          width: 100%;
        }
      }
    }
  }
}
