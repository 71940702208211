.category-order-detail-container {
  .layout-content {
    height: 100%;

    .app-content-container {
      display: flex;
      flex-direction: column;
      padding: 24px 12px 0 24px;
      min-width: 900px;
      // min-width: 747px;
      z-index: 1;
      .header-group {
        margin-bottom: 16px;
        padding-right: 12px;

        .page-info-container {
          display: flex;
          flex-direction: row;
          align-items: center;

          .page-title {
            flex: 1;

            .ant-typography {
              font-family: NotoSansTCBlack;
              font-size: 24px;
              font-weight: 900;
              color: #6461b4;
              margin-bottom: 0;
            }
          }

          .calendar-button {
            width: 58px !important;
            margin-right: 16px;
            svg {
              margin-left: auto;
              margin-right: auto;
            }
          }

          .ant-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 213px;
            height: 58px;
            padding: 0;
            color: #6461b4;
            background: #ffffff;
            border: 2px solid #d0cff4;
            transition: opacity 0.5s ease-in-out;

            svg {
              margin-right: 10px;
            }

            &:hover {
              background: #6461b4;
              border: 2px solid #6461b4;
              color: #ffffff;
              transition: opacity 0.5s ease-in-out;

              svg {
                margin-right: 10px;

                path {
                  fill: #ffffff;
                  transition: opacity 0.5s ease-in-out;
                }
              }
            }

            &.active-btn {
              background: linear-gradient(272.96deg, #28a9f1 0%, #65c8ff 100%);
              border: 2px solid #d0cff4;
              color: white;

              &:focus {
                background: linear-gradient(
                  272.96deg,
                  #28a9f1 0%,
                  #65c8ff 100%
                );
                border: 2px solid #d0cff4;
              }

              &:hover {
                background: linear-gradient(
                  272.96deg,
                  #28a9f1 0%,
                  #65c8ff 100%
                );
                border: 2px solid #d0cff4;
              }

              svg {
                path {
                  fill: #ffffff;
                }
              }
            }
          }
        }
      }

      .body-group {
        overflow-y: auto;
        flex: 1;

        .items-columns-container {
          display: grid;
          grid-template-columns: repeat(3,auto);
          align-items: center;
          grid-column-gap: 8px;
          // max-width: 209px;
        }

        .app-table {
          .table-header {
            .header-row {
              .column-cell {
                div {
                  text-align: center;
                }
              }
            }
          }

          .table-body {
            .item-row {
              .items-info-cell {
                align-items: start;

                .info-group-container {
                  flex: 1;
                  overflow: hidden;
                  // max-width: 126px;

                  .text-label {
                    overflow-wrap: break-word;
                    max-width: 220px;
                    font-weight: 400;
                    color: #4f4e66;
                    font-size: 14px;
                    @media only screen and (max-width: 1200px) {
                      max-width: 240px;
                    }
                    .ant-divider {
                      border-color: #4f4e66;
                    }
                  }

                  .text-info {
                    overflow-wrap: break-word;
                    max-width: 220px;
                    @media only screen and (max-width: 1200px) {
                      max-width: 240px;
                    }
                  }
                }
              }

              .quantity-button {
                width: 100%;
                display: flex;
                justify-content: center;

                .ant-btn {
                  width: 58px;
                  height: 55px;
                  padding: 0;
                  border-radius: 10px;
                  border: 2px solid #b5b6ff;
                  size: 18px;
                  font-weight: 700;
                  color: #4f4e66;

                  &:hover {
                    border: 4px solid #b530bc;
                    color: #4f4e66;
                    background: none;
                  }

                  &:focus {
                    border: 4px solid #b530bc;
                    color: #4f4e66;
                    background: none;
                  }

                  &:disabled {
                    border: 2px solid #f0f0fc;
                    background: #f7f7fd;
                    color: #bdbdbd;
                  }

                  &.ant-popover-open {
                    border: 4px solid #b530bc;
                    color: #4f4e66;
                    background: none;
                  }
                }
              }
            }

            .item-expand-row {
              .total-cost-container {
                display: flex;
                justify-content: flex-end;

                .ant-tag {
                  margin-right: 8px;
                  background: #f0f0fc;
                  color: #6461b4;
                  font-size: 16px;
                  font-weight: 700;
                  padding: 12px 8px;
                  border: none;
                  border-radius: 4px;
                }
              }
            }

            .group-item-expand-row {
              .group-item-expand-cell {
                img {
                  margin-right: 8px;
                }
                .ant-typography {
                  color: white;
                  font-size: 14px;
                  font-weight: 400;
                  margin: 0;
                }
              }
            }
          }
        }
      }

      .footer-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 24px 12px 24px 0;

        .ant-btn {
          height: 70px;
          padding: 0;
        }

        .back-button {
          width: 226px;
        }

        .save-button {
          width: 351px;
        }
      }
    }
    .category-order-detail-footer-group {
      background-color: #f3f3f9;
      z-index: 1;
    }
  }
  
}
