@font-face {
  font-family: NotoSansTC;
  src: url('./assets/fonts/NotoSansTC-Regular.otf');
}
@font-face {
  font-family: NotoSansTCBlack;
  src: url('./assets/fonts/NotoSansTC-Black.otf');
}
body {
  font-family: NotoSansTC;
  background-color: #f3f3f9;
}
#root {
  height: 100%;
  background-color: #f3f3f9;
}
#root {
  background-color: #f3f3f9;
}
.app-container {
  height: 100%;
  background-color: #f3f3f9;
}

.height-full {
  height: 100%;
}

.width-full {
  width: 100%;
}
.width-haft {
  width: 50%;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #bdbdbd;
  transform: translateZ(0);
}

::scrollbar {
  width: 5px;
  height: 5px;
}

::scrollbar-track {
  background: none;
}

::scrollbar-thumb {
  border-radius: 4px;
  background: #bdbdbd;
  transform: translateZ(0);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.delete-modal {
  .ant-modal-footer {
    .ant-btn-primary {
      background-color: #e94940;
      border-color: #e94940;
    }
  }
}
.submit-modal {
  .ant-modal-footer {
    .ant-btn-primary {
      background-color: #47bac1;
      border-color: #47bac1;
    }
  }
}
.ant-modal-content {
  .ant-modal-header {
    padding-bottom: 10px;
    .ant-modal-title {
      color: #455154;
    }
  }
  .ant-modal-body {
    padding-top: 10px;
  }
  .ant-btn.disabled,
  .ant-btn[disabled] {
    color: white;
    opacity: 0.65;
  }
}

.app-button {
  .ant-btn {
    border: 2px solid #6461b4;
    border-radius: 10px;
    background: none;
    padding: 20px 30px;
    color: #6461b4;
    font-weight: 700;
    font-size: 20px;
    text-shadow: none;
  }

  .ant-btn:focus {
    color: #6461b4;
    background: none;
    border: 2px solid #6461b4;
  }

  .ant-btn:active {
    color: #6461b4;
    background: none;
    border: 2px solid #6461b4;
  }

  .ant-btn:disabled {
    border: 2px solid #f0f0fc;
    background: #f0f0fc;
    color: #bdbdbd;
  }

  .ant-btn:not(.ant-btn-primary):hover {
    color: #6461b4;
    background: #f2f2f2;
    border: 2px solid #6461b4;
  }

  .ant-btn-primary {
    border: 1px solid #d8d8d8;
    background: linear-gradient(81.97deg, #a69bea 1.05%, #b530bc 84.79%);
    color: #ffffff;
    box-shadow: 0 4px 20px rgba(100, 97, 180, 0.1),
      0 1px 8px rgba(100, 97, 180, 0.12);
    text-shadow: none;
  }

  .ant-btn-primary:focus {
    border: 1px solid #d8d8d8;
    background: linear-gradient(81.97deg, #a69bea 1.05%, #b530bc 84.79%);
    color: #ffffff;
    box-shadow: 0 4px 20px rgba(100, 97, 180, 0.1),
      0 1px 8px rgba(100, 97, 180, 0.12);
  }

  .ant-btn-primary:active {
    border: 1px solid #d8d8d8;
    background: linear-gradient(81.97deg, #a69bea 1.05%, #b530bc 84.79%);
    color: #ffffff;
    box-shadow: 0 4px 20px rgba(100, 97, 180, 0.1),
      0 1px 8px rgba(100, 97, 180, 0.12);
  }

  .ant-btn-primary:hover {
    border: 1px solid #d8d8d8;
    background: linear-gradient(92.72deg, #c5c2ff 0%, #938fff 100%);
    box-shadow: 0 4px 20px rgba(100, 97, 180, 0.1),
      0 1px 8px rgba(100, 97, 180, 0.12);
  }
}

.app-table {
  .ant-table {
    background: transparent;
    .ant-table-container {
      .ant-table-content {
        table {
          border-spacing: 0 8px;
          .ant-table-thead {
            tr {
              th {
                padding: 17px 8px;
                background-color: #e5eafb;
                color: #6461b4;
                font-size: 14px;
                font-weight: 500;
              }
              th:first-child {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                color: #4f4e66;
                font-size: 16px;
                font-weight: 700;
              }
              th:last-child {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
              }
            }
          }

          .ant-table-tbody {
            tr {
              td {
                background-color: #ffffff;
                padding: 8px 8px;
              }

              td:first-child {
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
              }

              td:last-child {
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
              }
            }
          }
        }
      }
    }
  }
}

.app-card {
  .ant-card {
    .ant-card-body {
      padding: 12px 16px;
    }
  }
}

.app-scrollable-container {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: auto;
}

.app-content-container {
  height: 100%;
  min-width: 863px;
  padding: 24px 24px 0 24px;
  background: #f3f3f9;
}

.app-flex-container {
  width: 100%;
  display: flex;
  align-items: center;
}

.flex-end {
  justify-content: flex-end;
}

.flex-va-center {
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.ant-popover {
  padding-right: 5px !important;

  .ant-popover-content {
    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner {
      border-radius: 4px;
      background: #e2e7f5;
      border: 2px solid #d0d1ff;
      box-shadow: 0 5px 16px -5px rgba(85, 81, 180, 0.3);

      .ant-popover-inner-content {
        padding: 0;
      }
    }
  }
}

.app-progress {
}
.active-icon {
  cursor: pointer;
  path {
    fill: #6461b4;
  }
}

.modal-progress-container {
  padding-top: 32px;

  .MuiCircularProgress-root {
    width: 48px;
    height: 48px;
  }

  .MuiCircularProgress-colorPrimary {
    color: #6461b4;
  }
}

.selection-item-odds {
  background: #f9faff;
}

.selection-item-even {
  background: #f1f4fc;
}

.selection-item {
  display: flex;
  flex-direction: row;
  padding: 18px 24px;
  align-items: center;

  .item-code {
    text-align: start;
    width: 100px;
    color: #4f4f4f;
    font-size: 14px;
    font-weight: 400;
    margin-right: 22px;
  }

  .item-name {
    flex: 1;
    color: #4f4e66;
    font-size: 16px;
    font-weight: 700;
    text-align: start;
  }

  .item-action {
    margin-left: 16px;

    .ant-radio-wrapper {
      margin-right: 0;
    }
  }
}

.ant-radio-wrapper {
  --antd-wave-shadow-color: #6461b4;

  &:hover {
    .ant-radio {
      border-color: #6461b4;
    }
  }

  .ant-radio:not(.ant-radio-checked) {
    .ant-radio-inner {
      border-color: #b5b6ff;
    }
  }

  .ant-radio.ant-radio-checked {
    &:hover {
      .ant-radio-inner {
        border-color: #6461b4;
      }
    }

    .ant-radio-input:focus + .ant-radio-inner {
      border-color: #6461b4;
    }
  }

  .ant-radio-checked {
    .ant-radio-inner {
      border-color: #6461b4;

      &:after {
        background: #6461b4;
      }
    }
  }
}

.scrollable {
  overflow: auto;
  padding-right: 3px !important;
}

textarea.ant-input {
  border: 1px solid #b5b6ff;
  border-radius: 6px;
  resize: none;
  color: #4f4f4f;
  padding: 16px;
  background: rgba(255, 255, 255, 0.5);

  &:hover {
    border: 1px solid #6461b4 !important;
    border-radius: 6px;
  }

  &:focus {
    border: 1px solid #6461b4;
    border-radius: 6px;
    box-shadow: 0 5px 20px -5px rgba(132, 128, 255, 0.3);
  }
}

.ant-list-empty-text {
  display: none;
}
.ant-modal-mask {
  background-color: rgba(130, 130, 130, 0.3) !important;
}
