.info-group-container {
  .text-label {
    color: #979797;
    font-weight: 700;
    font-size: 14px;
  }

  .text-info {
    color: #4F4E66;
    font-size: 20px;
    font-weight: 900;
    margin: 0;
    min-height: 20px;
  }
}