.wapper-login {
  .container-login {
    .row-container {
      .container-form {
        .title-create-password {
          font-style: normal;
          font-weight: 900;
          font-size: 24px;
          line-height: 36px;
          /* identical to box height, or 150% */
          text-align: center;
          /* /Thirdary Text */
          color: #3056a3;
          padding: 24px;
        }
        .wapper-input-create-password {
          width: 100%;
          .input-field {
            .text-error-create-password {
              color: #f44336;
              font-size: 0.75rem;
              margin-top: 3px;
              margin-left: 5px;
            }
          }
          .button {
            background: linear-gradient(
              81.97deg,
              #a69bea 1.05%,
              #b530bc 84.79%
            );
            border: 1px solid #d8d8d8;
            box-sizing: border-box;
            box-shadow: 0px 4px 20px rgba(100, 97, 180, 0.1),
              0px 1px 8px rgba(100, 97, 180, 0.12);
            border-radius: 10px;
            height: 54px;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            width: calc(100% - 96px);
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            color: #ffffff;
            margin-bottom: 72px;
            margin-left: 48px;
            margin-right: 48px;
          }
        }
        .wapper-success-create-password {
          .subtitle-success-create-password {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            /* identical to box height, or 150% */

            text-align: center;

            color: #1d1d1d;
          }
          .wapper-icon-done {
            justify-content: center;
            display: flex;
            margin-top: 32px;
            margin-bottom: 44px;
          }
          .button {
            background: linear-gradient(
              81.97deg,
              #a69bea 1.05%,
              #b530bc 84.79%
            );
            border: 1px solid #d8d8d8;
            box-sizing: border-box;
            box-shadow: 0px 4px 20px rgba(100, 97, 180, 0.1),
              0px 1px 8px rgba(100, 97, 180, 0.12);
            border-radius: 10px;
            height: 54px;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            width: 100%;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            color: #ffffff;
          }
          .wapper-inline {
            display: flex;
            margin: 24px 0 36px 0;
            justify-content: center;
            .text {
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 21px;
              color: #4f4e66;
            }
            .button-create-account {
              padding: 0 0 0 8px;
              text-decoration-line: underline;
              color: #3056a3;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
