body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Roboto;
}

#root {
  overflow-x: hidden;
}
