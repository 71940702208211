.wapper-home-page {
  @media only screen and (min-width: 1440px) {
    // display: flex;
    // justify-content: center;
    margin: auto;
  }
  .page-container {
    max-width: 1440px;
    min-height: 100vh;
    @media only screen and (min-width: 1440px) {
      min-width: 1440px;
    }
    background: white;
    .page-header {
      height: 98px;
      background: white;
      padding: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .common-btn {
        border-radius: 4px;
        padding: 6px 34px;
        height: 37px;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        &.english-btn {
          background: linear-gradient(81.97deg, #a69bea 1.05%, #b530bc 84.79%);
        }
        &.last-btn {
          color: #828282;
          border: none;
        }
      }
    }
    .slick-dots {
      .dot-custom {
        width: 12px;
        height: 12px;
        background: #e0e0e0;
        border-radius: 12px;
      }
      .slick-active {
        .dot-custom {
          background: #1d1d1d;
        }
      }
    }

    .promotion-content {
      width: 100%;
      margin-top: 48px;
      padding: 14px;
      @media only screen and (min-width: 1280px) {
        padding: 32px 120px;
      }
      .promotion-title {
        font-weight: 900;
        font-size: 30px;
        line-height: 45px;
        text-align: center;
        color: #4f4e66;
        font-family: NotoSansTCBlack;
      }
      .card-group {
        cursor: grab;
        overflow: auto;
        width: 100%;
        // padding: 12px;
        display: flex;
        // flex-wrap: wrap;
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        .ant-card {
          // width: calc(25% - 24px);
          min-width: 353px;
          margin: 10px;
          border-radius: 6px;
          box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.04),
            0px 1px 7px rgba(0, 0, 0, 0.08);
          @media only screen and (max-width: 576px) {
            min-width: 300px;
          }
          &:after {
            content: '';
            display: block;
            position: absolute;
            right: -2rem;
            width: 12px;
            height: 1px;
          }
        }
        .ant-card-cover {
          margin-top: 0;
          margin-right: 0;
          margin-left: 0;
          img {
            border-radius: 6px 6px 0 0;
            pointer-events: none;
            width: 351px;
            height: 226px;
            object-fit: cover;
          }
        }
        .ant-card-meta-title {
          font-weight: bold;
          font-size: 18px;
          color: #4f4f4f;
        }
        .ant-card-meta-description {
          font-size: 18px;
          color: #4f4f4f;
        }
        .date-text {
          font-size: 12px;
          line-height: 18px;
          color: #828282;
        }
      }
      .view-more {
        display: flex;
        justify-content: flex-end;
        padding-right: 24px;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 40px;
      }
    }
    .sub-footer-content {
      width: 100%;
      padding: 32px 24px;
      background: #f3f3f9;
      display: flex;
      align-items: flex-start;
      @media only screen and (min-width: 1280px) {
        padding: 24px 130px;
      }
      .wapper-sub-footer {
        width: 100%;
        img {
          // margin-right: 48px;
        }
        .item-group {
          // width: 25%;
          // margin-right: 48px;
          margin-bottom: 24px;
          .title-item {
            font-weight: bold;
            font-size: 18px;
            line-height: 27px;
            color: #3056a3;
            margin-bottom: 16px;
            &.pointer {
              cursor: pointer;
            }
          }
          .text-item {
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            color: #4b4b4b;
            margin-bottom: 16px;
          }
        }
        .logo-sub-footer {
          margin-bottom: 32px;
        }
      }
    }
    .footer-content {
      background: #6461b4;
      padding: 16px 32px;
      display: flex;
      align-items: center;
      color: white;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      justify-content: space-between;
      .right-footer {
        span {
          padding: 0 32px;
        }
      }
    }
    .slick-dots {
      bottom: 24px;
    }
    .slick-prev,
    .slick-next {
      display: none !important;
    }
  }
}
.contact-header {
  padding: 40px 130px;
  font-weight: 900;
  font-size: 30px;
  line-height: 45px;
  color: #4f4e66;
  margin: 0;
  font-family: NotoSansTCBlack;
}
.contact-content {
  padding: 24px 130px;
  display: flex;
  .haft-content {
    width: 50%;
    &.right-block {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
    }
    .toa-logo-contain {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 76px;
    }
    .contact-item-group {
      display: flex;
    }
    .contact-block {
      background: #ffffff;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      width: 452px;
      box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.04),
        0px 1px 7px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
      padding: 32px;
      height: 593px;
      margin-bottom: 46px;
      display: flex;
      flex-direction: column;
      .MuiFormHelperText-root {
        color: #ef5f5f;
        font-family: NotoSansTC;
      }
      .contact-title-block {
        font-weight: 900;
        font-size: 30px;
        line-height: 45px;
        color: #6461b4;
        margin-bottom: 32px;
        font-family: NotoSansTCBlack;
      }
      .help-text {
        font-weight: normal;
        font-size: 10px;
        line-height: 15px;
        color: #828282;
        margin-bottom: 24px;
      }
      .succecss-box {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .check-circle {
        background: linear-gradient(120.76deg, #65c4e1 0%, #89d7bf 100%);
        width: 83px;
        height: 83px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .success-text {
        font-weight: 900;
        font-size: 30px;
        line-height: 45px;
        color: #6461b4;
        margin-bottom: 18px;
        font-family: NotoSansTCBlack;
      }
      .success-message {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #1d1d1d;
        margin-bottom: 42px;
      }
      .submit-btn {
        background: linear-gradient(81.97deg, #a69bea 1.05%, #b530bc 84.79%);
        border: 1px solid #d8d8d8;
        box-sizing: border-box;
        box-shadow: 0px 4px 20px rgba(100, 97, 180, 0.1),
          0px 1px 8px rgba(100, 97, 180, 0.12);
        border-radius: 10px;
        width: 100%;
        height: 60px;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: #ffffff;
      }
      .MuiFormHelperText-root.Mui-error {
        margin-bottom: 4px;
        margin-top: -20px;
      }
      .MuiOutlinedInput-root {
        margin-bottom: 20px;
        box-sizing: border-box;
        // box-shadow: 0px 3px 16px rgb(0 0 0 / 4%), 0px 1px 7px rgb(0 0 0 / 8%);
        border-radius: 10px;
      }
      .MuiFormLabel-asterisk {
        color: red;
      }
    }
  }
}
.title-block-item {
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #4f4e66;
  margin-bottom: 8px;
  padding: 0 130px;
}
.text-block-item {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #4f4e66;
  margin-bottom: 32px;
  padding: 0 130px;
}
.row-form-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
  width: 100%;
}
.input-form {
  margin-bottom: 10px;
}
@media only screen and (max-width: 1024px) {
  .contact-header {
    padding: 24px;
  }
  .contact-content {
    padding: 24px;
  }
  .title-block-item {
    padding: 0 24px;
  }
  .text-block-item {
    padding: 0 24px;
  }
}

@media only screen and (max-width: 768px) {
  .contact-header {
    padding: 24px;
  }
  .contact-content {
    flex-direction: column;
    padding: 24px;
    .haft-content {
      width: 100%;
      &.right-block {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .contact-block {
        width: 100%;
      }
    }
  }
}
