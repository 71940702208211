.received-detail-container {
  .layout-content {
    height: 100%;

    .app-content-container {
      display: flex;
      flex-direction: column;
      padding-bottom: 0;

      .header-group {
        margin-bottom: 16px;
        padding-right: 12px;

        .page-info-container {
          display: flex;
          flex-direction: row;
          align-items: center;

          .page-title {
            flex: 1;

            h3.ant-typography {
              font-family: NotoSansTCBlack;
              font-size: 20px;
              font-weight: 900;
              color: #6461B4;
              margin-bottom: 0;
            }

            .ant-typography {
              font-size: 16px;
              font-weight: 700;
              color: #4F4F4F;
              margin-bottom: 0;
            }
          }

          .ant-btn {
            width: 213px;
            height: 70px;
            padding: 0;
          }
        }
      }

      .body-group {
        overflow-y: auto;
        flex: 1;

        .loading-progress-container {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .item-damage-report {
          margin-bottom: 8px;
          border-radius: 10px;
          background: #FFFFFF;
          margin-right: 12px;

          .ant-card-body {
            padding: 16px;

            .info-group-container {
              margin-bottom: 10px;
            }

            .report-reason-container {
              display: flex;
              flex-direction: row;
              align-items: center;

              .info-group-container {
                flex: 1;
                margin-right: 16px;
              }
            }
          }
        }

        .report-reason-label {
          color: #979797;
          font-weight: 700;
          font-size: 14px;
          line-height: 27px;
        }

        .report-reason {
          color: #29ABE2;
          font-weight: 400;
          font-size: 18px;

          .reason-type {
            font-weight: 700;
          }
        }

        .item-info-label {
          font-weight: 400;
          color: #4F4E66;

          .ant-divider {
            border-color: #4F4E66;
          }
        }

        .item-info {
          font-weight: 700;
          font-size: 18px;
        }

        .app-table {
          height: auto;
          position: relative;

          .table-header {
            position: sticky;
            top: 0;
          }

          .table-body {
            flex: none;
            overflow: unset;

            .item-info-container {
              .item-info {
                font-weight: 700;
                font-size: 18px;
              }

              .item-info-label {
                font-weight: 400;
                color: #4F4E66;

                .ant-divider {
                  border-color: #4F4E66;
                }
              }
            }

            .quantity-button {
              width: 100%;
              display: flex;
              justify-content: center;

              .ant-btn {
                width: 102px;
                height: 55px;
                border: 2px solid #B5B6FF;
                border-radius: 10px;
                font-size: 18px;
                padding: 0;
              }
            }

            .actual-quantity {
              .ant-btn {
                color: #B5B6FF;
                font-weight: 400;
              }
            }

            .received-quantity {
              .ant-btn {
                color: #4F4E66;
                font-weight: 700;
              }
            }
          }
        }
      }

      .footer-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 24px 12px 24px 0;

        .ant-btn {
          height: 70px;
          padding: 0;
        }

        .back-button {
          width: 226px;
        }

        .received-delivery-button {
          width: 334px;
        }
      }
    }
  }
}

.modal-rp-dmg-item-selection {
  .action-button {
    .ant-btn {
      width: 100%;
      height: 70px;
      padding: 0;
      margin-top: 48px;
    }
  }
}

.modal-rp-dmg-action {
  .ant-btn {
    width: 100%;
    height: 70px;
    padding: 0;
    margin-bottom: 24px;
  }
}

.modal-rp-dmg-input-reason {
  textarea {
    height: 416px;
    font-size: 20px;
    font-weight: 400;
  }
}

.modal-rp-dmg-form {
  display: flex;
  flex-direction: column;

  .ant-card {
    border: none;
    border-radius: 8px;
    background: #FFFFFF;
    margin-bottom: 8px;

    .ant-card-head {
      border-bottom: none;
      padding: 0;

      .ant-card-head-title {
        text-align: left;
        padding: 16px 16px 0 16px;
        font-size: 18px;
        font-weight: 700;
        color: #6461B4;
      }
    }

    .ant-card-body {
      display: flex;
      flex-direction: row;
      padding: 16px;
      position: relative;

      .report-action-group {
        position: absolute;
        top: -31px;
        right: 16px;
      }

      .selected-items {
        display: flex;
        flex-direction: column;

        .item-info {
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: baseline;

          .item-code {
            text-align: start;
            width: 150px;
            font-weight: 400;
            font-size: 14px;
            color: #4F4F4F;
          }

          .item-name {
            flex: 2;
            font-weight: 400;
            font-size: 16px;
            color: #4F4F4F;
            text-align: left;
          }
        }
      }

      .item-reason {
        .reason-type {
          text-align: start;
          font-weight: 700;
          font-size: 18px;
          color: #4F4E66;
        }

        .other-reason {
          text-align: start;
          font-weight: 400;
          font-size: 18px;
          color: #4F4E66;
        }
      }

      .list-photos {
        display: flex;
        flex-direction: row;

        .item-photo {
          width: 97px;
          height: 77px;
          background: #F7F7FD;
          border-radius: 6px;
          margin-right: 12px;
          border: none;
        }

        .item-add {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          svg {
            width: 30px;
            height: 30px;
          }

          &:hover {
            background: #f4f4fa;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.report-action-group {
  .btn-action {
    height: 45px;
    width: 70px;
    padding: 0;
    border-radius: 10px;
  }

  .action-edit {
    border: 2px solid #B5B6FF;

    &:focus {
      border: 2px solid #B5B6FF !important;
    }

    &:hover {
      border: 2px solid #B5B6FF !important;
    }
  }

  .action-delete {
    border: 2px solid #FC537E;
    margin-left: 16px;

    &:focus {
      border: 2px solid #FC537E !important;
    }

    &:hover {
      border: 2px solid #FC537E !important;
    }
  }
}