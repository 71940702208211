.file-input {
  display: none;
}
.file-name {
  display: inline-block;
  vertical-align: top;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  text-decoration-line: underline;

  /* /Secondary Text */

  color: #6461b4;
}

.file-error {
  display: inline-block;
  vertical-align: top;
  // margin-left: 50px;
  color: #9aa9bb;
}

.file-error-message {
  color: red;
}

.file-type-logo {
  width: 50px;
  height: 50px;
  // background: url(../images/generic.png) no-repeat center center;
  background-size: 100%;
  position: absolute;
}

.file-size {
  display: inline-block;
  vertical-align: top;
  color: #30693d;
  margin-left: 10px;
  margin-right: 5px;
  margin-left: 10px;
  color: #444242;
  font-weight: 700;
  font-size: 14px;
}

.file-remove {
  position: absolute;
  top: 20px;
  right: 10px;
  line-height: 15px;
  cursor: pointer;
  color: red;
  margin-right: -10px;
}
