.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0 24px;
  padding: 0 24px;
  width: 100%;
  .left-header {
    .title-info {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #4f4e66;
      margin-right: 40px;
      margin-bottom: 8px;
      .title-value {
        color: #828282;
      }
    }
  }
  .title-header {
    font-weight: 900;
    font-size: 24px;
    line-height: 36px;
    color: #6461b4;
  }
}
.page-content {
  padding: 0 12px 0 24px;
  margin-top: 16px;
  height: calc(var(--vh, 1vh) * 100 - 212px);
  overflow: auto;
  .item-group {
    display: flex;
    margin: 0 -6px;
    width: calc(100% + 12px);
    margin-bottom: 16px;
    // &:last-child {
    //   margin-bottom: 10px !important;
    // }
    .favourite-item {
      border: 4px solid #d0d1ff;
      box-sizing: border-box;
      box-shadow: 0px 5px 20px -5px rgba(132, 128, 255, 0.3);
      border-radius: 16px;
      width: calc(100% / 3);
      height: 216px;
      margin: 0 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: white;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      &:first-child {
        margin: 0 6px 0 4px;
      }
      &:last-child {
        margin: 0 4px 0 6px;
      }
      &.item-0 {
        background: linear-gradient(81.97deg, #a69bea 1.05%, #b530bc 84.79%);
      }
      &.item-1 {
        background: linear-gradient(272.96deg, #2593d0 0%, #80d1ff 100%);
      }
      &.item-2 {
        background: linear-gradient(120.76deg, #65c4e1 0%, #89d7bf 100%);
      }
      .selected-text {
        font-size: 16px;
        line-height: 24px;
      }
      .selected-value {
        font-weight: bold;
        font-size: 35px;
      }
    }
    &.categories-group {
      flex-wrap: wrap;
      width: calc(100% + 16px);
      margin: 0 -8px;
      .normal-item {
        background: #ffffff;
        border: 2px solid #d0d1ff;
        box-sizing: border-box;
        box-shadow: 0px 5px 20px -5px rgba(132, 128, 255, 0.3);
        border-radius: 16px;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #4f4e66;
        height: 137px;
        width: calc(25% - 12px);
        margin: 6px;
        cursor: pointer;
      }
    }
  }
}
.page-content-vehicle-schedule {
  height: calc(var(--vh, 1vh) * 100 - 107px);
}
.page-footer {
  position: absolute;
  bottom: 0;
  padding: 24px 0;
  background: unset;
  .footer-btn {
    font-weight: bold;
    font-size: 20px;
    // line-height: 30px;
    width: 226px;
    text-align: center;
    color: #6461b4;
    border: 2px solid #5855a6;
    box-sizing: border-box;
    border-radius: 10px;
    height: 70px;
    // padding: 20px 88px;
    background-color: #f3f3f9;
  }
}
.vehicle-detail {
  padding: 0;
  .title-info {
    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
    color: #6461b4;
  }
  .header-fix {
    position: sticky;
    z-index: 1;
    position: --webkit-sticky;
    top: 0;
    background-color: #f3f3f9;
  }

  .header-fill {
    height: 10px;
    background-color: #f0f2f5;
    z-index: 1;
    position: sticky;
    position: --webkit-sticky;
    top: 50px;
  }
  .header-group {
    display: flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #6461b4;
    background: #e5eafb;
    border-radius: 4px;
    padding: 8px 16px 8px 12px;
    &.vehicle-item {
      background: white;
      padding: 8px;
      border-radius: 6px;
      margin-bottom: 8px;
      .value-item {
        background: #f7f7fd;
        border-radius: 10px;
        padding: 8px 16px;
        font-weight: bold;
        font-size: 18px;
        line-height: 27px;
        color: #828282;
      }
    }
    .items-column {
      width: calc(100% - 200px);
      text-align: left;
      display: flex;
      flex-direction: column;
      color: #4f4e66;
      font-weight: bold;
    }
    .vehicle-column {
      width: 110px;
      min-width: 100px;
      max-width: 110px;
      text-align: center;
    }
    .order-column {
      width: 100px;
      min-width: 100px;
      max-width: 100px;
      text-align: center;
    }
  }
  .wapper-title-vehicle {
    position: sticky;
    z-index: 1;
    position: --webkit-sticky;
    top: 58px;
    background-color: #f3f3f9;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    .title-vehicle-item {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      background: #817eb8;
      border-radius: 4px;
      margin-bottom: 8px;
      padding: 10px 12px;
    }
  }
}
.padding-right {
  padding: 0 12px 0 0;
  overflow-y: hidden;
}
// refactor
.title-info {
  color: #4f4e66;
  margin-right: 40px;
  margin-bottom: 8px;
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  color: #6461b4;
  .title-value {
    color: #828282;
  }
}
.value-item {
  background: #f7f7fd;
  border-radius: 10px;
  padding: 8px 16px;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #828282;
}
.page-content-categories {
  padding: 0 12px 0 2px;
}
.scrollable-container-categories {
  height: calc(var(--vh, 1vh) * 100);
}
.container-vehicle-schedule-detail {
  padding: 24px 12px 0 24px;
  .app-table .table-body .item-row {
    box-shadow: 0px 5px 20px -5px rgba(132, 128, 255, 0.3);
  }
}
.vehicle-schedule-detail {
  .header-row {
    align-items: center;
  }
}