.wapper-new-and-promotion {
  padding: 52px 50px;
  min-height: 50vh;
  .promotion-title {
    font-weight: 900;
    font-size: 30px;
    line-height: 45px;
    color: #4F4E66;
    font-family: NotoSansTCBlack;
    padding: 0 20px;
    margin-top: 24px;
    margin-bottom: 18px;
  }
  .card-group {
    cursor: grab;
    overflow: auto;
    width: 100%;
    padding: 10px;
    display: flex;
    // flex-wrap: wrap;
    padding-bottom: 10px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    .ant-card {
      // width: calc(25% - 24px);
      // min-width: 380px;
      margin: 12px;
      border-radius: 6px;
      box-shadow: 0px 5px 20px -5px rgba(132, 128, 255, 0.3);
      @media only screen and (max-width: 576px) {
        // min-width: 250px;
        width: 230px;
      }
      border: unset;
      &:after {
        content: '';
        display: block;
        position: absolute;
        right: -2rem;
        width: 12px;
        height: 1px;
      }
    }
    .ant-card-cover {
      margin-top: 0;
      margin-right: 0;
      margin-left: 0;
      justify-content: center;
    display: flex;
    align-items: center;
      img {
        border-radius: 6px 6px 0 0;
        pointer-events: none;
        object-fit: cover;
      }
    }
    .ant-card-body {
      padding: 12px 101px 24px 24px;
      .ant-card-meta-title {
        color: #4F4E66;
        font-weight: 700;
        font-size: 18px;
      }
      .ant-card-meta-description {
        color: #4F4E66;
        font-size: 18px;
        .time {
          color: #828282;;
          font-size: 12px;
        }
      }
    }
  }
  .wapper-load-more{
    display: flex;
    justify-content: center;
    margin-top: 30px;
    cursor: pointer;
    
    .load-more {
      // display: flex;
      align-items: center;
      text-align: center;
  
      color: #6461B4;
      border: 2px solid #6461B4;
      box-sizing: border-box;
      border-radius: 10px;
      width: 226px;
      height: 64px;
      justify-content: center;
      display: flex;
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
    }
  }
  .wapper-loading-news{
    height: 64px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  
}
@media only screen and (min-width: 1280px) {
  .wapper-new-and-promotion {
    padding: 24px 150px;
  }
}
@media only screen and (max-width: 576px) {
  .wapper-new-and-promotion {
    .card-group {
      padding: 0;
      justify-content: center;

    }
  }
}