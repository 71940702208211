.inventory-detail-container {
  height: 100%;

  .layout-container {
    height: 100%;

    .app-content-container {
      display: flex;
      flex-direction: column;
      padding-bottom: 0;

      .header-group {
        .page-info-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 16px;
          padding-right: 12px;

          .page-title {
            flex: 1;

            h3.ant-typography {
              font-family: NotoSansTCBlack;
              font-size: 24px;
              font-weight: 900;
              color: #6461B4;
              margin-bottom: 4px;
            }

            .ant-typography {
              font-size: 17px;
              font-weight: 700;
              color: #4F4F4F;
            }
          }

          .ant-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 213px;
            height: 58px;
            padding: 0;
            color: #6461b4;
            background: #ffffff;
            border: 2px solid #d0cff4;
            transition: opacity 0.5s ease-in-out;
            border-radius: 10px;
            font-size: 20px;
            font-weight: bold;

            svg {
              margin-right: 10px;
            }

            &:hover {
              background: #6461b4;
              border: 2px solid #6461b4;
              color: #ffffff;
              transition: opacity 0.5s ease-in-out;

              svg {
                margin-right: 10px;

                path {
                  fill: #ffffff;
                  transition: opacity 0.5s ease-in-out;
                }
              }
            }

            &.active-btn {
              background: linear-gradient(272.96deg, #28a9f1 0%, #65c8ff 100%);
              border: 2px solid #d0cff4;
              color: white;

              &:focus {
                background: linear-gradient(
                  272.96deg,
                  #28a9f1 0%,
                  #65c8ff 100%
                );
                border: 2px solid #d0cff4;
              }

              &:hover {
                background: linear-gradient(
                  272.96deg,
                  #28a9f1 0%,
                  #65c8ff 100%
                );
                border: 2px solid #d0cff4;
              }

              svg {
                path {
                  fill: #ffffff;
                }
              }
            }
          }
        }
      }

      .body-group {
        overflow-y: auto;
        flex: 1;

        .items-columns-container {
          display: grid;
          grid-template-columns: repeat(3,auto);
          align-items: center;
          grid-column-gap: 8px;
        }

        .ant-form {
          width: 100%;
          height: 100%;

          .ant-form-item {
            margin-bottom: 0;
          }

          .ant-form-item-has-error {
            .ant-input {
              border-color: #ff4d4f !important;

              &:hover {
                border-color: #ff4d4f !important;
              }
            }
          }
        }

        .app-table {
          .table-body {
            .item-info-container {
              .item-info {
                font-weight: 700;
                font-size: 18px;
              }

              .item-info-label {
                font-weight: 400;
                color: #4F4E66;

                .ant-divider {
                  border-color: #4F4E66;
                }
              }
            }

            .input-cell {
              font-size: 18px;
              font-weight: 700;
              color: #4f4e66;
              text-align: center;

              .ant-btn {
                width: 100%;
                height: 55px;
                padding: 0;
                border-radius: 10px;
                border: 2px solid #b5b6ff;
                font-size: 18px;
                font-weight: 700;
                color: #4f4e66;

                &:hover {
                  border: 4px solid #b530bc;
                  color: #4f4e66;
                  background: none;
                }

                &:focus {
                  border: 4px solid #b530bc;
                  color: #4f4e66;
                  background: none;
                }

                &.ant-popover-open {
                  border: 4px solid #b530bc;
                  color: #4f4e66;
                  background: none;
                }
              }
            }

            .input-error {
              .ant-btn {
                border-color: #ff4d4f;

                &:hover {
                  border-color: #ff4d4f;
                }

                &:focus {
                  border-color: #ff4d4f;
                }

                &.ant-popover-open {
                  border-color: #ff4d4f;
                }
              }
            }

            .expiry-date-input {
              width: 102px;
              font-weight: 400;
              color: #B5B6FF;

              .placeholder-date-format {
                font-weight: 400;
                color: #B5B6FF;
              }
            }

            .remaining-quantity-input {
              width: 111px;
            }

            .remark-input-container {

              .remark-input-label {
                font-size: 18px;
                font-weight: 700;
                color: #4F4F4F;
                padding: 11px 0 16px 0;
              }

              .remark-input {
                color: #4F4F4F;
                font-size: 20px;
                font-weight: 400;

                textarea {
                  height: 264px;
                  color: #4F4F4F;
                  font-size: 20px;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }

      .footer-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 24px 12px 24px 0;

        .ant-btn {
          height: 70px;
          padding: 0;
        }

        .back-button {
          width: 226px;
        }
        .save-button {
          width: 303px;
          background: var(--gradient-2, linear-gradient(315deg, #2593D0 0%, #80D1FF 100%));
          &:disabled {
            border: 2px solid #f0f0fc;
            background: #f7f7fd;
            color: #bdbdbd;
          }
        }

        .submit-button {
          width: 351px;
          &:disabled {
            border: 2px solid #f0f0fc;
            background: #f7f7fd;
            color: #bdbdbd;
          }
        }
      }
    }
  }
}

.inventory-detail-modal {
  .ant-modal-content {
    width: 500px !important;

    .modal-message {
      padding: 0 30px;
    }
  }
}