.wapper-login {
    .container-login {
        .row-container { 
            .container-form {
                .logo {
                    width: 162px;
                    padding: 44px 0;
                }
                .title-signin {
                    font-style: normal;
                    font-weight: 900;
                    font-size: 24px;
                    line-height: 36px;
                    /* identical to box height, or 150% */
                    text-align: center;
                    /* /Thirdary Text */
                    color: #3056A3;
                    padding: 24px;
                }
                .input-field {
                    padding: 0 48px;
                    width: 100%;
                    margin-bottom: 24px;
                    @media only screen and (max-width: 576px) {
                        padding: 0 24px;
                    }
                    .text-error-login {
                        color: #f44336;
                        font-size: 0.75rem;
                        margin-top: 3px;
                        margin-left: 5px;
                    }
                }
                .button {
                    background: linear-gradient(81.97deg, #a69bea 1.05%, #b530bc 84.79%);
                    border: 1px solid #d8d8d8;
                    box-sizing: border-box;
                    box-shadow: 0px 4px 20px rgba(100, 97, 180, 0.1),
                        0px 1px 8px rgba(100, 97, 180, 0.12);
                    border-radius: 10px;
                    height: 54px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    width: calc(100% - 96px);
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    color: #ffffff;
                    cursor: pointer;
                }
                .text-forgot-password {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    text-decoration-line: underline;
                    color: #3056a3;
                    margin-top: 16px;
                    cursor: pointer;
                }
                .inline {
                    display: flex;
                    margin: 24px 0 36px 0;
                    justify-content: space-around;
                    @media only screen and (max-width: 576px) {
                        justify-content: space-between;
                    }
                    
                    .text {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 21px;
                        color: #4F4E66;
                    }
                    .button-create-account {
                        padding: 0 0 0 8px;
                        text-decoration-line: underline;
                        color: #3056A3;
                        cursor: pointer;
                    }
                    
                }
            }
        }
        
    }
}
.MuiFormHelperText-contained {
    margin-left: 5px !important;
}
